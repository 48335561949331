import { Typography, Container } from '@mui/material';

function RenderTextWithBreaks({ text }) {
    return (
        <>
            {text.split('\n').map((str, index, array) => 
                index === array.length - 1 ? str : <>
                    {str}
                    <br/>
                </>
            )}
        </>
    );
}

function PrivacyPolicy() {
    const content = `DOCDAO: Privacy Notice

    This Privacy Notice ("Notice") is prepared to inform you, the users of the DOCDAO platform (hereinafter referred to as "Processing Activities"), about the collection, use, and disclosure ("Processing") of personal data by Mediverse Co., Ltd. (hereinafter referred to as "Service Provider" or "We") as a personal data controller in the personal data collected from you for these Processing Activities.
    We have developed the DOCDAO platform as an online community for all physicians to exchange information, opinions, knowledge, experiences related to but not limited to various medical fields, current research, and to organize activities and services of interest to the online community.
    For the provision of such services, we process your personal data as follows:
    
    1.	Legal Basis for Processing Personal Data
    We process your personal data under the following bases:
    1.1. Performance of a Contract: When you register an account on the DOCDAO platform, we need to use your personal data for identification and to deliver services according to the contract or as chosen by you on the DOCDAO platform. We cannot provide services on the DOCDAO platform on a contractual basis without your personal data.
    1.2. Legal Obligations: We may be required to provide your personal data to authorities or governmental bodies that have the legal authority to request such information that we have collected, such as the National Police Office, Office of the Attorney General, courts, Revenue Department, etc.
    1.3. Legitimate Interests: For our lawful interests or those of others, such as offering information or benefits in a non-personalized manner, etc. These interests are no less significant than your basic rights in personal data. It may also be for the purposes of the company’s service provision, such as improving operational processes, risk management, fraud prevention, and implementing security measures for our and your assets.
    1.4. Consent: As part of our service provision, we may seek your consent to collect, use, or disclose your personal data to provide you with maximum benefits and/or to enable us to offer services that meet your needs. This will be for purposes where we need to collect, use, or disclose sensitive personal data, such as using a photo of your medical professional license (which includes sensitive personal data like your facial image) and your facial image for verifying your identity before transactions or specific services.
    
    2.	Purposes for Collecting Your Personal Data
    We collect your personal data for the following purposes:
    2.1. To identify and verify your identity in using services on the DOCDAO platform.
    2.2. To provide various services available on the DOCDAO platform to you.
    
    3.	Personal Data We Collect and Use
    For the purposes mentioned in section 2, we collect the following personal data of yours:
    3.1. Sources and list of personal data collected are as follows:
    Sources/Methods of Collection	List of Personal Data
    1. Data collected directly from you through user account registration or identity verification in the platform's verification system.	
    -	Name
    -	Surname
    -	Phone number
    -	Email
    -	Date of birth
    -	Address
    -	National ID number
    -	Medical license number
    2. Data collected using detection or tracking technologies to monitor your usage behavior.	
    -	Cookie data 
    -	IP address 
    -	Application Logging 
    -	Device ID 
    -	Browsing history
    
    3.2 Purpose of Using Personal Data
    Purpose of Using Personal Data	List of Personal Data Used
    1. For registration, for identity verification, and to apply for services.	
    -   Name
    -	Surname
    -	Phone number
    -	Email
    -	Date of birth
    -	Address
    -	National ID number
    -	Medical license number
    2. To provide various services to you.
    -	Name
    -	Surname
    
    4.	Disclosure of Your Personal Data
    We may disclose your data to the public, if necessary, through various channels such as the company's website, platform announcement boards, social media announcements, or other media like newspapers, radio, and television.
    
    5.	Your Rights under the Personal Data Protection Act B.E. 2562 (2019)
    The Personal Data Protection Act B.E. 2562 (2019) aims to give you more control over your personal data. Once the provisions related to the rights of personal data owners are enforced, you have the following rights under the Act:
    5.1. Right to Access: The right to access, copy, and request disclosure of the source of your personal data that we collect, except in cases where we have the right to refuse your request under the law or court orders, or if your request may adversely affect the rights and freedoms of others.
    5.2. Right to Rectification: The right to request correction of your incorrect or incomplete personal data to ensure accuracy, currency, completeness, and prevent misunderstanding.
    5.3. Right to Restrict Processing: The right to request suspension of the use of your personal data in the following cases:
    5.3.1. During the period in which we are verifying your request to correct your personal data to be accurate, complete, and up to date.
    5.3.2. If your personal data has been collected, used, or disclosed unlawfully.
    5.3.3. When your personal data is no longer necessary for the purposes for which it was collected as we have informed you, but you request us to continue storing this data for exercising your legal rights.
    5.3.4. During the period in which we are proving the lawful grounds for collecting your personal data, or reviewing the necessity of collecting, using, or disclosing your personal data for public benefit, due to your exercise of the right to object to the collection, use, or disclosure of your personal data.
    5.4. Right to Object: The right to object to the collection, use, or disclosure of your personal data, except in cases where we have lawful reasons to reject your request (e.g., when collection, use, or disclosure of your personal data is legally justified or for establishing, complying with, or exercising legal claims, or for significant public interest missions).
    
    6.	International Transfer of Your Personal Data
    We transfer or send your personal data abroad for the operation or service of the DOCDAO platform, ensuring adherence to the criteria for the protection of personal data transferred abroad as announced by the Personal Data Protection Committee, except in the following cases:
    6.1. As required by law, that necessitates us to transfer or send personal data abroad.
    6.2. We have informed you and obtained your consent in cases where the destination country does not have adequate data protection standards, as per the list of countries announced by the Personal Data Protection Committee.
    6.3. To prevent or mitigate harm to your or someone else's life, body, or health when you are unable to consent at that time, or to carry out important public interest missions.
    
    7.	Data Retention Period
    We retain your personal data for as long as you have an account on the DOCDAO platform, and for a period of 10 years following the deletion of your account. After this period, we will delete, destroy, or anonymize your personal data when it is no longer necessary, or use it for other purposes such as statistical analysis, performance improvement, or significant public interest.
    
    8.	Data Security
    We implement appropriate technical and managerial measures to ensure the security of your personal data, preventing loss, unauthorized access, destruction, use, alteration, or disclosure, in line with our Information Security Policy. Additionally, we have established a Personal Data Protection Policy, disseminated throughout the organization, along with guidelines for secure collection, use, and disclosure of personal data, maintaining confidentiality, integrity, and availability of personal data. We periodically review this policy and this notice.
    
    9.	Participation of Data Subjects
    We may disclose personal data only upon requests from data subjects or their legal representatives, heirs, lawful agents, or guardians, by submitting a request through specified contact methods (such as email). In cases where data subjects, their heirs, lawful agents, or guardians object to data storage, accuracy, or any action such as notifying for personal data correction, we will record such objections as evidence. We may refuse the rights you wish to exercise in cases specified by law or when your personal data is made unidentifiable or when you do not provide sufficient information to identify yourself in exercising your rights.
    
    10.	Responsibilities of Persons Processing Personal Data
    We have designated specific officials with relevant authority and duties in the collection, use, and disclosure of personal data for these Processing Activities to access your personal data. We ensure strict adherence to this notice.
    
    11.	Changes to the Privacy Notice
    We may consider updating or changing this notice as deemed appropriate and will inform you through the DOCDAO platform announcement or other communication channels. The latest version date is indicated at the end of the notice. However, we recommend that you regularly check for updates, especially before disclosing your personal data to us. By using products or services under these Processing Activities, you acknowledge the terms in this notice. Please stop using the services if you disagree with the terms in this notice. If you continue using the services after updates to this notice are announced through the above channels, it is considered that you have acknowledged the changes.
    
    12.	Contact for Inquiries
    You can contact us regarding this notice at:
    12.1. Data Controller
    Name: Mediverse Co., Ltd.
    Contact Address: 61/160 Soi Tavee Mitra 7, Rama 9 Road
    Huai Kwang Sub-district, Hai Kwang District
    Bangkok 10310 Thailand 
    Contact Channel: support@docdao.io
    12.2. Data Protection Officer (DPO)
    Name: Mediverse Co., Ltd.
    Contact Address: 61/160 Soi Tavee Mitra 7, Rama 9 Road
    Huai Kwang Sub-district, Hai Kwang District
    Bangkok 10310 Thailand 
    Contact Channel: dpo@docdao.io
    
    13. Version 1.0.0, Last Updated on November 18, 2023
    `;

    return (
        <Container>
            <br/>
            {/* <Typography variant="h2" gutterBottom color="textPrimary">
                DOCDAO: Privacy Notice
            </Typography> */}
            <Typography variant="h5" gutterBottom color="textPrimary">
                <RenderTextWithBreaks text={content} />
            </Typography>
        </Container>
    );
}

export default PrivacyPolicy;
import { Typography, Container } from '@mui/material';

function RenderTextWithBreaks({ text }) {
    return (
        <>
            {text.split('\n').map((str, index, array) => 
                index === array.length - 1 ? str : <>
                    {str}
                    <br/>
                </>
            )}
        </>
    );
}
function TermsOfUse() {
    const content = `DOCDAO: Term and Conditions

    1.	Definitions
    Unless otherwise specified in these terms and conditions, the following definitions apply:
    Platform: Refers to the DOCDAO digital platform, a variety of digital services developed or provided by Mediverse Co., Ltd. with the objective of creating an online community for healthcare professionals to exchange information, ideas, knowledge, experiences, and other relevant medical or research-related data. This also includes activities or services that members of this online community may find interesting.
    Service Provider: Refers to Mediverse Co., Ltd.
    Service User: Refers to anyone using any services on the DOCDAO platform provided by the Service Provider.
    Service: Refers to access to information or services through the DOCDAO platform.
    Service Request Information: Refers to the information used to access information or services through the DOCDAO platform. Users are responsible for providing detailed information such as ID numbers or information to be linked from collaborating service providers.
    Collaborating Service Provider: Refers to any individual or legal entity providing information or services that Service Users can access through the DOCDAO platform.
    Service Support System (Backend): Refers to the digital system of Collaborating Service Providers that supports data linking, request acceptance, and the approval/authorization process or services linked to the DOCDAO platform.
    Service Officer of Collaborating Service Providers: Refers to officers responsible for evaluating service requests, documents/evidence accompanying requests, as well as approving or authorizing them through the service support system.
    User Account: Refers to the set of data related to an individual that is uniquely identifiable and can be used to specify or classify the person recorded and stored in digital format. It may be used to indicate or classify individuals in electronic transactions with service providers or collaborating service providers.
    Authenticator: Refers to something that an individual owns and controls to use for authentication to access transactional services with the Service Provider or Collaborating Service Providers. It may be in the form of a User ID and/or Password.
    These Terms and Conditions: Refers to the terms and conditions of service usage of the DOCDAO platform, as well as any additional amendments.
    Additional Terms and Conditions: Refers to agreements and conditions other than these terms and conditions that are separate from and related to the DOCDAO platform, such as the Privacy Policy of the Service Provider and the Privacy Notice, Data Processing Addendum, and Cookie Policy of the DOCDAO platform. These documents discuss the collection, use, processing, and disclosure of the personal information of service users linked through the platform for the operation of the collaborating service providers, etc.
    
    2.	Acceptance of Terms and Conditions
    2.1. The service user acknowledges and agrees that accepting these terms and conditions signifies that the user has read, understood, and agrees to be bound by the criteria and conditions specified in these terms and conditions, as well as any additional terms and conditions.
    2.2. The service user acknowledges and agrees that collaborating service providers may have additional terms and conditions for the provision of services. The user has read, understood, and agrees to be bound by the additional terms and conditions of collaborating service providers.
    2.3. If the service user does not accept these terms and conditions, or any additional terms and conditions, or the service terms of collaborating service providers, please discontinue use and request the termination of the platform usage by contacting the service provider at E-mail: support@docdao.io or through other channels specified by the service provider.
    
    3.	Service Registration
    3.1. To use the DOCDAO platform, you must register to create a user account and apply for services on the platform provided by the Service Provider through the channels specified by the Service Provider. You must register with accurate, true, and current information, and you must undergo identity verification as per the steps specified by the Service Provider (to fully utilize all platform services).
    3.2. In the event of issues with service registration, user registration, or rights assignment, users can contact the Service Provider at E-mail: support@docdao.io. In such cases, the Service Provider may request users to submit necessary documents or undertake additional actions, such as providing copies of identity documents or professional medical licenses (Medical License), to confirm the user's identity.
    3.3. Service users can report issues, seek additional advice, or make changes to service details by contacting E-mail: support@docdao.io of the Service Provider or through other channels specified by the Service Provider.
    
    4.	User Qualifications
    4.1. Service users must be at least 18 years old.
    4.2. Service users must hold a medical degree from a certified medical faculty.
    4.3. Service users must possess a valid Medical License at the time-of-service application and during their tenure as platform users. If a service user's Medical License is suspended or revoked, the Service Provider reserves the right to consider withdrawing the user's platform usage rights. The Service Provider is solely responsible for determining whether to revoke, temporarily suspend, or make no changes to the status of such service users.
    
    5.	Account Usage and Maintenance
    5.1. User accounts created and registered by service users are for the sole use of the respective users. Users cannot transfer or delegate the rights or responsibilities of their user accounts to others.
    5.2. For user safety, the platform requires the use of identity confirmation mechanisms (Authenticator) during operations.
    5.2.1. Users must set a login password during the registration process, which is used to authenticate and access user accounts. This password is also used to send service commands through the platform and/or services presented via the platform.
    5.3. Users must not disclose their identity confirmation mechanisms (Authenticator) to others and must keep them confidential. Any service commands sent through the user's account using the identity confirmation mechanisms (Authenticator) are considered valid and binding on the user. The Service Provider is not liable for any damages resulting from the loss or disclosure of identity confirmation mechanisms (Authenticator) or any other incidents.
    5.4. Users can independently modify and change their identity confirmation mechanisms (Authenticator) according to the methods and criteria specified by the Service Provider.
    5.5. Users must specify their identity confirmation mechanisms (Authenticator) each time they use the service. If the provided identity confirmation mechanisms (Authenticator) do not match the Service Provider's specifications, the Service Provider may automatically suspend platform usage, and users will be unable to use the identity confirmation mechanisms (Authenticator) again. Users must contact the Service Provider at E-mail: support@docdao.io to lift the temporary suspension. Users must comply with the criteria, conditions, and procedures specified by the Service Provider.
    5.6. In case users forget their identity confirmation mechanisms (Authenticator), they can contact the platform of the Service Provider or use other channels specified by the Service Provider.
    5.7. The Service Provider may send electronic mail or automatic notification messages (SMS) to users through the email address or mobile phone number specified by users. Users should notify the Service Provider of any changes to this information at E-mail: support@docdao.io or through other channels specified by the Service Provider.
    5.8. Users can suspend or cancel their user accounts and/or platform usage at any time through various channels specified by the Service Provider. The Service Provider is not responsible for any damage that occurred before the suspension or cancellation becomes effective. If users wish to lift the account suspension and/or service usage, they can contact the Service Provider through the specified channels.
    
    6.	Service Usage
    6.1. Service users acknowledge and agree that the service provider serves as a channel and facilitator for accessing various services offered through the platform or by collaborating with service providers. The service provider is not liable for any damages incurred by service users, service recipients, or any third party arising from services provided by any collaborating service providers.
    6.2. Service users acknowledge and agree that information related to accessing platform services, provided by service users to the service provider and collaborating service providers, is essential for accessing platform services. The service provider is not responsible for verifying any information, and once the service provider has acted based on the provided information, it is deemed correct and complete. If any defects or errors arise from actions taken based on the provided information, the service user accepts responsibility for the actions and resulting damages, and the service provider is not liable.
    6.3. Service users certify that images, names, symbols, logos, and/or any information provided by users or imported into the platform for display or operation are intellectual property of the service users. Service users have the right to use this information for the specified purposes. If any damage occurs to the service provider due to the use of such images, names, symbols, logos, and/or information in platform services, the service user is solely responsible for compensation.
    6.4. In cases where service users use services or engage in transactions of various types through the DOCDAO platform, and defects or errors occur for any reason, service users agree to grant the service provider the authority to rectify such defects or errors accurately and comprehensively. In this regard, documents related to the service provider's actions to rectify the defects or errors are considered accurate, complete, and legally valid evidence.
    6.5. The platform may contain advertisements, hyperlinks, or deep links to websites or applications of third parties, including but not limited to links to platforms, websites, or applications of affiliated entities, trade partners, or other related entities. These third-party platforms, websites, or applications are not part of the service provider's platform and are not under the control or responsibility of the service provider. When users link to these third-party platforms, websites, or applications, users do so at their own risk, and the service provider does not guarantee the accuracy, precision, or reliability of the information provided on the websites of third parties. The service provider disclaims all liability for any loss or damage suffered by users from referencing or relying on information present or absent on the third-party platforms, websites, or applications. Advertisements do not constitute endorsements or recommendations by the service provider.
    
    7.	Responsibilities of Collaborating Service Providers
    7.1. Collaborating service providers must develop, improve, and maintain the backend system, including the infrastructure and related equipment associated with the services provided by the collaborating service providers. This is to ensure efficient connectivity and collaboration with the platform.
    7.2. Collaborating service providers must respond to data requests from service users in a timely manner, whether through automated processes or manual processes by service provider staff after the operation.
    7.3. Collaborating service providers must establish appropriate systems and security measures for the platform, including infrastructure and related equipment associated with the services provided by the collaborating service providers. This is to prevent damage, disruptions, or any significant negative impact on the platform or the service provider.
    7.4. If the backend system or infrastructure and related equipment associated with the services provided by the collaborating service providers encounter issues preventing the provision of services to one or more service users, the collaborating service provider must immediately notify the service provider. Additionally, the collaborating service provider must collaborate with the service provider to rectify the situation promptly, allowing service users to resume using the services.
    7.5. In cases where the collaborating service provider acts as the Data Controller with the authority to make decisions, define formats, and determine purposes for processing personal data, as per the Personal Data Protection Act of 2019, the collaborating service provider agrees to appoint the service provider as the Data Processor. This arrangement is detailed in the Data Processing Addendum of the DOCDAO platform.
    
    8.	Service Utilization Integrity and User Commitment
    8.1. Service users acknowledge that any records or documents prepared by the service users in connection with the use of the platform are accurate in all respects, and the service users are not required to verify or sign them before use.
    8.2. If any information, documents, or details provided by the service users to the service provider have errors or defects, and the service users wish to rectify such errors or defects or make changes to any information, the service users must notify the service provider in writing, along with supporting documentation, before the service provider enters the data into its system or forwards the information to collaborating service providers. If the service users notify the service provider after the data has been entered into the system or forwarded to collaborating service providers, the service provider and collaborating service providers reserve the right not to modify or change the information, and the service users agree to assume responsibility for any resulting damages.
    8.3. Service users certify that all data, documents, or details presented and/or submitted to the service provider, regardless of the format, are true and accurate. In cases where service errors occur due to errors or defects in the data, documents, or details, or where any defects or errors arise from actions taken by the service provider or collaborating service providers based on such information, the service users agree to waive any claims and responsibilities against the service provider and collaborating service providers for such errors or defects.
    8.4. Service users acknowledge and agree that the platform's role is limited to receiving and forwarding data and/or maintaining logs of data exchanges between service users and collaborating service providers. The service provider cannot access or interfere with the content of exchanged data.
    8.5. Service users acknowledge and agree that the contractual relationship (including claims and liabilities) between the service provider and the service users related to the products and services offered by the service provider is solely binding between the service provider and the service users. The service provider is directly responsible to the service users for any products and services provided to the service users, and the service provider will not be held responsible for the products and services of the service users.
    8.6. Service users agree not to take any action that would involve the service provider or collaborating service providers in disputes with other parties related to the provision of services through the platform under the terms and conditions herein. In the event of any dispute or claim, the service users will handle it themselves without involving the service provider or collaborating service providers.
    
    9.	Liability and Exceptions to Liability of the Service Provider
    9.1. The service provider shall be liable to the service users only in the following cases:
    9.1.1. The service provider has not yet delivered user account credentials to the service users, and unauthorized transactions occur on the platform.
    9.1.2. Unauthorized transactions take place through the platform, and it is not the fault of the service users.
    9.2. If, during various operations, the service users cannot connect to the platform, the operation is considered unsuccessful. If the service users wish to proceed with any operation, they must reconnect to the platform to initiate the operation again.
    9.3. The service users agree and acknowledge that the service provider and collaborating service providers shall not be held liable for the service provider's inability to comply with the terms and conditions of this agreement or for any unsuccessful or delayed transactions or service use due to various circumstances, including but not limited to the following:
    9.3.1. The service users violate the terms and conditions of this agreement, additional terms and conditions, or service terms of the collaborating service providers.
    9.3.2. Force majeure events or other events for which the service provider and collaborating service providers are not liable, such as system defects, damages, disruptions, or delays caused by computer systems, power outages, external actions, including attacks by hackers, natural disasters, protests, transportation disruptions, riots, wars, computer viruses, or harmful data, or other events beyond the control of the service provider.
    9.3.3. The service provider and collaborating service providers have not received the necessary supporting documents for the service request from the service users.
    9.3.4. Any reasons specified by the service provider and/or applicable law.
    9.3.5. The service provider and collaborating service providers require a reasonable amount of time to perform the necessary steps to provide the service.
    9.3.6. The service provider and collaborating service providers have a necessity, disturbance, or any reasonable cause.
    9.3.7. In cases where the computer systems or communication systems of the service provider, collaborating service providers, or affiliated companies involved in the service, or the Internet Service Provider, Mobile Operator, telecommunication network systems, or other systems related to the service experience faults, damages, disruptions, or temporary closures for maintenance purposes, making it impossible for the service users to use the service. The service users agree not to claim the service provider, collaborating service providers, and/or affiliated companies responsible for any damages arising from such disruptions.
    9.4. The provision of service does not cover problems arising from the backend support system (if any) of the collaborating service providers and the use of equipment that the service users have obtained for their own use. If the service users wish the service provider to investigate such problems, the service users must submit a written request to the service provider for consideration on a case-by-case basis.
    
    10.	Suspension and/or Termination of Service
    10.1. If the service provider wishes to terminate the service platform, the service provider shall notify the service users in writing at least 30 days in advance. When the specified period expires, these terms and conditions shall be deemed terminated immediately. However, the termination of such service does not extinguish the rights and/or obligations that the service provider and the service users have towards each other, arising from any actions taken before the termination of service in accordance with the terms and conditions of this agreement.
    Furthermore, the service users agree to acknowledge that the service provider has the right to suspend and/or terminate the service immediately if the service users violate the terms and conditions of this agreement, additional terms and conditions, or service terms of the collaborating service providers. Alternatively, if there are events that lead the service provider to believe, with reasonable grounds, that the service users are using the service platform with the intention of fraud or causing harm to other individuals, or if the service users create and/or use forged documents to support service requests on the platform, or in the event of changes due to the issuance of new laws or changes in existing laws or changes in the enforcement of any law, orders, regulations, or conditions that deviate from the original, or in the event that governmental authorities issue new orders, regulations, or conditions that require the service provider to suspend or terminate the service, the service provider has the right to suspend or terminate the service platform immediately without prior notice. In this regard, the service users agree not to claim any damages from the service provider for such actions.
    10.2. In the event that the service provider discovers that there has been modification or alteration of the equipment, including the system of the user's equipment used for the service platform, such as jailbreaking for iPhone operating systems (iOS) or rooting for Android operating systems on phones, the service provider has the right to suspend or terminate the service platform of the service users immediately without prior notice, for the security of the service users and the service. The service users agree to waive the right to claim any damages from the service provider in any case.
    10.3. The service provider has the right to suspend or terminate the service at any time by notifying the service users at least 30 days in advance, unless the service provider deems that complying with these terms and conditions may violate the law, public order and morality, or may have a detrimental effect on the overall service provision of the service provider, or if there are problems related to security or the necessity to maintain or repair the service platform or related systems. In such cases, the service provider has the right to suspend or terminate the service immediately without prior notice. The service users agree not to claim any damages from the service provider arising from such suspension or termination.
    10.4. The service provider has the right to temporarily suspend the service or terminate the service for users who have been temporarily suspended or have had their licenses revoked by the regulatory authority, without the need for prior notice. In this case, the suspension or termination shall take effect immediately upon notification to the suspended or revoked users. This right to consider temporary suspension or termination is the sole right of the service provider. The service users agree to act in accordance with the consideration of the service provider and waive the right to claim any damages arising from the decision to temporarily suspend or terminate the service, whether through legal proceedings, criminal proceedings, or administrative proceedings.
    During the consideration process by the service provider, it is considered that users who are awaiting the outcome are still service users with the rights granted under the terms of this service agreement until the consideration results are received. The consideration process by the service provider may begin from the discovery of information by the service provider or notification from any other person.
    
    11.	Security and Personal Data Protection
    11.1. The service provider dedicatedly selects and uses technology and security measures to safeguard the integrity and security of transactions conducted over the computer network. This is done to prevent unauthorized access to the user's data during data transmission over communication networks or from data tampering by individuals or networks that are not authorized to connect to the service provider's network.
    11.2. The service provider has the service provider has use technology use technology and security measures to protect the user's data that is processed and stored on the platform.
    11.3. The service provider emphasizes the importance of protecting personal data, as indicated in the Privacy Notice and Data Processing Addendum of the platform.
    11.4. The service users agree to allow the service provider or persons authorized by the service provider to access their personal data for the purpose of verification, identification, problem resolution, and support services during the use of the service. This access is granted upon request from the service user or for notification purposes, communication, and coordination as necessary.
    
    12.	Contact and Notices
    12.1. Notices or other communications under these terms and conditions or related agreements must be made in writing and delivered in person or sent by mail or email to the following addresses:
    12.2. Service Provider: 
    Mediverse Co., Ltd.
    61/160 Soi Tavee Mitra 7, Rama 9 Road
    Huai Kwang Sub-district, Hai Kwang District
    Bangkok 10310 Thailand
    Email for Contact: support@docdao.io
    Telephone Number for Contact: 02-0239920
    12.3. Service User: As per the contact information provided by the service user during the account registration process.
    
    13.	Applicable Law
    13.1.	These terms and conditions are governed by and subject to Thai law, and the Thai courts have jurisdiction over any disputes arising under these terms and conditions.
    
    14.	Agreement Amendment
    14.1.	The service provider reserves the right to amend, modify, or add to these terms and conditions, as well as any details related to the platform, including additional terms and conditions, without prior notice to the service users.
    
    15.	Take Down Notice Procedure
    15.1.	If a service user discovers content, posts, or messages that may be illegal or infringe on the rights of individuals or legal entities, they can notify the service provider to suspend access, visibility, or delete the content, posts, or messages by following the procedure below:
    15.1.1. Submit a claim specifying the desired action and explaining how the content, posts, or messages constitute illegal activity or cause harm to the service user or others.
    15.1.2. Provide daily records or complaint letters (if any).
    15.1.3. Submit a copy of an ID card or passport (for foreign nationals).
    15.1.4. Provide documents or evidence submitted to investigators or police officers (if any).
    15.1.5. Submit other documents or evidence that detail the illegal activity or harm caused to the service user or others (if any).
    The decision to take down information from the computer system is the sole discretion and right of the service provider. If the information provided by the service user is incomplete or insufficient to clearly demonstrate how the content, posts, or messages constitute illegal activity or violate the rights of individuals or legal entities, the service provider may refuse to take action. If the service provider determines that taking down the information may affect the rights of others, the service user requesting the removal must provide a court judgment as the final and conclusive evidence to support the claim.
    
    16.	General Agreement
    16.1. The service provider may be required to disclose user information to government officials or agencies when requested or ordered to do so under the law, announcements, or regulations of the government.
    16.2. The service provider has the right to transfer any rights and/or duties under these terms and conditions to other individuals without the need for consent or prior notification to the service users. However, the rights and/or duties under these terms and conditions of the service users cannot be transferred to others unless there is prior written consent from the service provider.
    
    17.	Version 1.0.0, Last Updated on November 18, 2023
    `;

    return (
        <Container>
            <br/>
            {/* <Typography variant="h2" gutterBottom color="textPrimary">
                DOCDAO - Terms and Conditions
            </Typography> */}
            <Typography variant="h5" gutterBottom color="textPrimary">
                <RenderTextWithBreaks text={content} />
            </Typography>
        </Container>
    );
}

export default TermsOfUse;
import React from 'react';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import YourData from './YourData';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import LandingPage from './LandingPage';
//import { red } from '@mui/material/colors';
import TopBar from './TopBar';
import Footer from './Footer';

const theme = createTheme({
  palette: {
    text: {
      primary: '#ffffff',
    },
  },

  typography: {
    h2: {
      fontSize: '3rem', fontWeight: 600//, textTransform: 'uppercase'
    },
    h3: {
      fontSize: '2rem', fontWeight: 600, textTransform: 'uppercase'
    },
    h4: {
      fontSize: '1.5rem', fontWeight: 600
    },
    h5: {
      fontSize: '1.3rem',
    },
  },

});

let backgroundImage = '/dd-bg-1.jpg';
// console.log(window.location.pathname)
// switch (window.location.pathname) {
//   case '/':
//     backgroundImage = '/dd-bg-1.jpg';
//     break;
//   case '/privacy-policy':
//     backgroundImage = '/dd-bg-2.jpg';
//     break;
//   case '/terms-of-use':
//     backgroundImage = '/dd-bg-3.jpg';
//     break;
//   default:
//     backgroundImage = '/none'; // some default background
// }
const backgroundStyle = {
  backgroundImage: `linear-gradient(rgba(128, 0, 128, 0.5), rgba(128, 0, 128, 0.5)), url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  minHeight: '100vh',
  filter: "grayscale(50%)"
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div style={backgroundStyle}>
          <TopBar />
          <Box style={{ minHeight: 'calc(100vh - 100px)', paddingBottom: '100px' }}>
            <Routes>
              <Route exact path="/" element={<LandingPage />} />
              <Route path="/your-data" element={<YourData />} />
              <Route path="/privacy-notice" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsOfUse />} />
            </Routes>
            <div> {/* padding for footer */}
              &nbsp;
            </div>
          </Box>
        </div>
        <Footer />
      </Router>
    </ThemeProvider >
  );
}

export default App;
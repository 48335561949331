// TopBar.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';

function TopBar() {
    return (
        <AppBar position="sticky" style={{ backgroundColor: "blueviolet" }}>
            <Toolbar>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <Avatar src="/logo-dd.png" alt="DOCDAO Logo" style={{ marginRight: '10px' }} />
                    <Typography variant="h6">
                        DOCDAO
                    </Typography>
                </Link>                
                <Link to="/your-data" style={{ color: "white", textDecoration: 'none', marginRight: '15px' }}>
                    <Button color="inherit">Your Data</Button>
                </Link>
                <Link to="/privacy-notice" style={{ color: "white", textDecoration: 'none', marginRight: '15px' }}>
                    <Button color="inherit">Privacy Notice</Button>
                </Link>
                <Link to="/terms-and-conditions" style={{ color: "white", textDecoration: 'none' }}>
                    <Button color="inherit">Terms and Conditions</Button>
                </Link>
            </Toolbar>
        </AppBar>
    );
}

export default TopBar;

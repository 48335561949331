import { Typography, Container, Button } from '@mui/material';

function YourData() {
    const mailtoLink = `mailto:support@docdao.io?subject=Data deletion request&body=Hi there,
    I wish for you to delete all data associated with my DOCDAO account:
    
    PLEASE ENTER YOUR DOCDAO REGISTERED EMAIL ADDRESS HERE.
    
    Thank you,`; // %0D%0A%0D%0A

    return (
        <Container>
            <br/>
            <Typography variant="h2" gutterBottom color="textPrimary">
                DOCDAO: Your Data
            </Typography>
            <Typography variant="h5" gutterBottom color="textPrimary">
                If you wish to have all data associated with your DOCDAO account deleted, please click the link below to send us an email request.
                <br/>
                <br/>
                Make sure to include your DOCDAO-registered email address in the email body.
            </Typography>
            <br/>
            {/* <Typography variant="h5" gutterBottom color="textSecondary">
                <b><Link href={mailtoLink} style={{ textDecoration: "none" }}>Request Data Deletion</Link></b>
            </Typography> */}
            <Button variant="contained" color="primary" href={mailtoLink} style={{ textDecoration: "none" }}>
                Request Data Deletion
            </Button>
        </Container>
    );
}

export default YourData;
import React from 'react';
import { Box, Container, Typography } from '@mui/material';

function Footer() {
    return (
        <Box
            bgcolor="black"
            color="white"
            py={2}
            position="fixed" // Set the position to fixed
            bottom={0} // Align it to the bottom of the viewport
            left={0} // Align it to the left of the viewport
            right={0} // Stretch it to the right of the viewport
            zIndex="tooltip" // Ensure it's on top of other content
            padding="4px"
        >
            <Container>
                <Typography style={{ fontSize: "12px", textAlign: "center" }} gutterBottom>
                    <b>© Mediverse Co. Ltd.</b>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    61/160 Taveemit, Rama 9 Rd, Bangkok, 10310, Thailand
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <a style={{ textDecoration: "underline", color: "white" }} href='email://support@docdao.io'>support@docdao.io</a> / +66 992 233 889
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
import React from 'react';
import { Typography, Container, Box } from '@mui/material';

function LandingPage() {
    return (
        <Container>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                <br/>
                <Typography variant="h2" gutterBottom color="textPrimary">
                    Welcome to DOCDAO
                </Typography>
                <br/>
                <Typography variant="h3" gutterBottom color="textPrimary">
                    AN EXCLUSIVE ONLINE COMMUNITY FOR THAI DOCTORS
                </Typography>
                <br/>
                <Typography variant="h4" gutterBottom color="textPrimary">
                    The Community
                </Typography>
                <Typography variant="h5" gutterBottom color="textPrimary">
                    Membership within the online community is restricted solely to Thai medical doctors holding valid Thai medical certificates.
                    Facilitating connections among fellow doctors throughout the healthcare spectrum, including governmental organisations, medical schools, private hospitals, clinics, and more, bridging gaps that would otherwise confine them to isolated spheres of interaction.
                    A Safe space, A Playground, designed to be the space for doctors to express their thoughts openly, communicate using a common language and share mutual witticisms.
                    An environment fostering the exchange and exploration of medical expertise while also embracing discussions about non-medical aspects of daily life and personal passions. This creates an authentic community where true connections are established and flourish.
                    The accumulation of knowledge honed through years of experience, spanning from medical school to leadership roles within the medical field, encompassing both technical and psychological dimensions of the profession, stands as a crucial skill to be transmitted across generations within the community.
                    A hub for innovative tech-driven projects, research, treatments, medical businesses, public health policies, and impactful social ventures, enhancing Thai healthcare.
                </Typography>
                <br/>

                <Typography variant="h4" gutterBottom color="textPrimary">
                    Enhance Productivity
                </Typography>
                <Typography variant="h5" gutterBottom color="textPrimary">
                    Tailored for doctors, the community’s value propositions offer benefits such as streamlined tasks for reduced workload, increased efficiency, and potential income opportunities through innovative solutions. Advanced technology integration eases medical practice burdens, enhancing patient care. Organised knowledge and connections ensure accessible information, facilitating informed decisions. Ultimately, elevate professional effectiveness, instilling security and peace of mind, and fostering a successful, fulfilling medical practice.
                </Typography>
                <br/>

                <Typography variant="h4" gutterBottom color="textPrimary">
                    Impact
                </Typography>
                <Typography variant="h5" gutterBottom color="textPrimary">
                    Unlike other social platforms, the higher purpose of Docdao is to turn Noise into Voice through polling/voting capabilities.
                    The aim is to strengthen isolated voices and efforts of doctors by fostering collaboration and unity at both medical practice and policy-making levels.
                    The community values inclusivity and diversity based on geography, demographics, and gender, prioritising open-mindedness over hierarchy. We hope to challenge existing governing bodies and authorities to devise improved methods for advancing Thailand's healthcare system.
                    A mindset of decentralisation and self-governance encourages doctors on the platform to shape the community's direction and platform development. Members can take the lead, guide, participate, support, or invest in new business or social projects initiated within the community.
                </Typography>
            </Box>
        </Container>
    );
}

export default LandingPage;